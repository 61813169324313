* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Noto Sans', sans-serif;
}

nav .active {
	color: pink;
}

a:hover,
button:hover {
	cursor: pointer;
}

form {
	display: block;
	width: 100%;
}

body {
	min-height: 101vh;
	max-width: 1100px;
	margin: auto auto;
}

button {
	-moz-box-shadow: inset 0px 1px 3px 0px #91b8b3;
	-webkit-box-shadow: inset 0px 1px 3px 0px #91b8b3;
	box-shadow: inset 0px 1px 3px 0px #91b8b3;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0.05, #768d87),
		color-stop(1, #6c7c7c)
	);
	background: -moz-linear-gradient(top, #768d87 5%, #6c7c7c 100%);
	background: -webkit-linear-gradient(top, #768d87 5%, #6c7c7c 100%);
	background: -o-linear-gradient(top, #768d87 5%, #6c7c7c 100%);
	background: -ms-linear-gradient(top, #768d87 5%, #6c7c7c 100%);
	background: linear-gradient(to bottom, #768d87 5%, #6c7c7c 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#768d87', endColorstr='#6c7c7c',GradientType=0);
	background-color: #768d87;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid #566963;
	display: inline-block;
	cursor: pointer;
	color: #ffffff;
	font-family: Arial;
	font-size: 15px;
	font-weight: bold;
	padding: 11px 23px;
	text-decoration: none;
	text-shadow: 0px -1px 0px #2b665e;
}

button:hover {
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0.05, #6c7c7c),
		color-stop(1, #768d87)
	);
	background: -moz-linear-gradient(top, #6c7c7c 5%, #768d87 100%);
	background: -webkit-linear-gradient(top, #6c7c7c 5%, #768d87 100%);
	background: -o-linear-gradient(top, #6c7c7c 5%, #768d87 100%);
	background: -ms-linear-gradient(top, #6c7c7c 5%, #768d87 100%);
	background: linear-gradient(to bottom, #6c7c7c 5%, #768d87 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6c7c7c', endColorstr='#768d87',GradientType=0);
	background-color: #6c7c7c;
}

button:active {
	position: relative;
	top: 1px;
}
