.folder-list {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-auto-rows: minmax(35px, auto);
	grid-template-areas:
		'tab'
		'add';
	width: 75%;
}

.tab-button {
	text-align: center;
	grid-area: add;
	width: 100%;
}
.tab-struct {
	width: 100%;
	justify-content: space-around;
	grid-area: tab;
}

.tabs li {
	display: inline;
	/* Makes a horizontal row */
	float: left;

	/* So the psueudo elements can be
       abs. positioned inside */
	position: relative;
}
.tabs .NavLink {
	/* Make them block level
       and only as wide as they need */
	float: left;
	padding: 10px 40px;
	text-decoration: none;

	/* Default colors */
	color: black;
	background: #6e7f7e;

	/* Only round the top corners */
	-webkit-border-top-left-radius: 15px;
	-webkit-border-top-right-radius: 15px;
	-moz-border-radius-topleft: 15px;
	-moz-border-radius-topright: 15px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}
.tabs .active {
	/* Highest, active tab is on top */
	z-index: 3;
}
.tabs .active .NavLink {
	/* Colors when tab is active */
	background: white;
	color: black;
}
.tabs li:before,
.tabs li:after,
.tabs li .NavLink:before,
.tabs li .NavLink:after {
	/* All pseudo elements are 
       abs. positioned and on bottom */
	position: absolute;
	bottom: 0;
}
/* Only the first, last, and active
     tabs need pseudo elements at all */
.tabs li:last-child:after,
.tabs li:last-child .NavLink:after,
.tabs li:first-child:before,
.tabs li:first-child .NavLink:before,
.tabs .active:after,
.tabs .active:before,
.tabs .active .NavLink:after,
.tabs .active .NavLink:before {
	content: '';
}
.tabs .active:before,
.tabs .active:after {
	background: white;

	/* Squares below circles */
	z-index: 1;
}
/* Squares */
.tabs li:before,
.tabs li:after {
	background: #6e7f7e;
	width: 10px;
	height: 10px;
}
.tabs li:before {
	left: -10px;
}
.tabs li:after {
	right: -10px;
}
/* Circles */
.tabs li .NavLink:after,
.tabs li .NavLink:before {
	width: 20px;
	height: 20px;
	/* Circles are circular */
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	background: #222;

	/* Circles over squares */
	z-index: 2;
}
.tabs .active .NavLink:after,
.tabs .active .NavLink:before {
	background: #6e7f7e;
}
/* First and last tabs have different
     outside color needs */
.tabs li:first-child.active .NavLink:before,
.tabs li:last-child.active .NavLink:after {
	background: #222;
}
.tabs li .NavLink:before {
	left: -20px;
}
.tabs li .NavLink:after {
	right: -20px;
}
